import { useState, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GrayGridCell from "./GrayGridCell";
import Popover from "@mui/material/Popover";
import Switch from "@mui/material/Switch";
import { Alert, Snackbar } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import Button from "@mui/material/Button";
function TargetGridCell({
  url,
  timestamp,
  objectList,
  rowIndex,
  setObjectList,
  checkedRows,
  setCheckedRows,
  event,
  editMode,
  swapTracks,
  isTargetRow,
  resetTargetCell,
  standardKey,
  outerList,
  setTargetRow,
  row,
}) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [imgError, setImgError] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [disabled, setDisabled] = useState(false);

  let handleImgError = () => {
    setImgError(true);
  };

  const handleSwitchChange = () => {
    const objectListResult = [...objectList];
    let eventObj = objectListResult[1][event];
    eventObj.isChecked = !eventObj.isChecked;
    if (eventObj.isChecked === true) {
      if (checkedRows.size === 2 && !checkedRows.has(rowIndex)) {
        eventObj.isChecked = false;
        setSnackbarOpen(true);
      } else {
        const resultCheckedRows = new Set(checkedRows);
        resultCheckedRows.add(rowIndex);
        setCheckedRows(resultCheckedRows);
      }
    } else {
      setObjectList(objectListResult);
    }
  };
  /*
          if (rowIndex in checkedCells.keys()) {
            setCheckedRows((prev) => ({
              ...prev,
              rowIndex: [...checkedRows[rowIndex], colIndex],
            }));
          } else {
            setCheckedRows((prev) => ({
              ...prev,
              rowIndex: [colIndex],
            }));
          }
        }
      } else {
        let index = checkedRows[rowIndex].indexOf();
      }
        {editMode === true && (
            <Switch
              disabled={loading}
              onChange={handleSwitchChange}
              checked={objectList[rowIndex][1][event].isChecked}
              size="small"
              sx={{ margin: "0px", marginBottom: "2px", marginLeft: "auto" }}
            ></Switch>
      */

  const handlePopoverOpen = (e) => {
    setAnchorElement(e.currentTarget);
  };
  const handleTargetEventDisable = () => {
    console.log(row);
    let resObjectRow = structuredClone(row);
    resObjectRow[1][event]["disabled"] = true;
    setTargetRow(resObjectRow);
  };
  const handlePopoverClose = () => {
    setAnchorElement(null);
  };
  console.log(row[1]);
  if (row[1][event]["disabled"] === true) {
    return null;
  }
  if (imgError) {
    return (
      <GrayGridCell grayKey={standardKey} timestamp={timestamp}></GrayGridCell>
    );
  }
  const open = Boolean(anchorElement);
  return (
    <TableCell
      key={standardKey}
      onClick={
        editMode === false
          ? (e) => handlePopoverOpen(e)
          : () => swapTracks(rowIndex, event)
      }
      align="center"
      component={Paper}
      sx={{
        position: "relative",
        backgroundColor: "#fafafa",
        padding: "4px 4px",
      }}
    >
      {event !== "DRIVE_THRU_ORDER_ENTER" && (
        <button
          className="target-delete-event-button"
          onClick={handleTargetEventDisable}
        >
          <ClearIcon
            fontSize="5px"
            sx={{
              color: "white",
            }}
          ></ClearIcon>
        </button>
      )}
      <Snackbar
        message={"Only two rows can be selected at once"}
        autoHideDuration={3000}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Only two rows can be selected at once
        </Alert>
      </Snackbar>
      <Paper className="image-and-time-container">
        {isTargetRow === true && (
          <Button
            variant="contained"
            color="error"
            onClick={() => resetTargetCell(event)}
            sx={{
              width: "20%",
              padding: "0px",
              marginBottom: "2px",
              margin: "auto",
              fontSize: "11px",
            }}
          >
            Reset
          </Button>
        )}
        <img
          className={
            editMode === false
              ? "grid-image"
              : isTargetRow === true
              ? "target-grid-image"
              : "edit-grid-image"
          }
          alt="car object"
          value={event}
          src={url}
          onError={handleImgError}
        />
        <div className="grid-cell-timestamp">
          <AccessTimeIcon
            style={{ position: "relative", right: "4px", fontSize: "18px" }}
          ></AccessTimeIcon>
          {timestamp}
        </div>
      </Paper>
    </TableCell>
  );
}
export default TargetGridCell;
