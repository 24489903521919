import Button from "@mui/material/Button";
import TableCell from "@mui/material/TableCell";
import { useEffect, useState } from "react";
import TrackModal from "../Edit Modal/TrackModal";
function ActionCell({
  rowIndex,
  swapTracks,
  checkedRows,
  loading,
  objectList,
  setObjectList,
  headers,
  row,
  setCheckedRows,
  activeLocation,
  date,
  convertUnixToDate,
  actionKey,
  authRequest,
  user,
}) {
  const [disabled, setDisabled] = useState(false);
  const [cancelListCopy, setcancelListCopy] = useState([...objectList]);
  const [trackModalOpen, setTrackModalOpen] = useState(false);

  const handleTrackModalOpen = () => {
    setTrackModalOpen(true);
  };
  useEffect(() => {
    if (
      disabled === false &&
      (!checkedRows.has(rowIndex) || checkedRows.size < 2)
    ) {
      setDisabled(true);
    }
    if (
      disabled === true &&
      checkedRows.has(rowIndex) &&
      checkedRows.size === 2
    ) {
      setDisabled(false);
    }
  }, [checkedRows]);

  return (
    <TableCell
      key={actionKey}
      sx={{ borderBottomn: "none", padding: "0px 0px" }}
      className="action-cell"
    >
      <Button
        className="action-button"
        variant="contained"
        disabled={loading}
        sx={{
          "&:disabled": {
            backgroundColor: "#62d61a",
            opacity: "70%",
            color: "white",
          },
          "&:hover": { backgroundColor: "#3d8013" },
          marginX: "0px",
          backgroundColor: "#51a81b",
          textTransform: "none",
          color: "white",
          padding: "8px 2px",
          fontSize: "14px",
          width: "100%",
          borderRadius: "0px",
        }}
        onClick={() => handleTrackModalOpen()}
      >
        Connect Track
      </Button>

      {trackModalOpen ? (
        <TrackModal
          user={user}
          authRequest={authRequest}
          convertUnixToDate={convertUnixToDate}
          saveToList={objectList}
          setSaveToList={setObjectList}
          setCancellationList={setObjectList}
          objectList={
            objectList[rowIndex][1].hasOwnProperty("neighbors")
              ? Object.entries(objectList[rowIndex][1]["neighbors"])
              : []
          }
          trackModalOpen={trackModalOpen}
          setTrackModalOpen={setTrackModalOpen}
          headers={headers}
          row={row}
          loading={loading}
          editObjects={objectList[rowIndex]}
          checkedRows={checkedRows}
          setCheckedRows={setCheckedRows}
          setObjectList={setObjectList}
          swapTracks={swapTracks}
          rowIndex={rowIndex}
          activeLocation={activeLocation}
          date={date}
        ></TrackModal>
      ) : null}
    </TableCell>
  );
}

export default ActionCell;
