import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export const ProtectedRoute = ({ component, theme }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <CircularProgress
        size={80}
        sx={{
          position: "absolute",
          left: "50%",
          top: "40%",
        }}
      ></CircularProgress>
    ),
  });

  return <Component theme={theme} />;
};
