import Select from "@mui/material/Select";
import { FormControl } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
function PageList({ pages, currentPage, setCurrentPage }) {
    return (
        <div id="page-count-content">
        <FormControl
            fullWidth={true}
            sx={{
                width: "10%",
                "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                        borderColor: "#1d3557",
                    },
                },
                "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                        borderColor: "#1d3557",
                    },
                },
            }}
        >
            Page Count
            <Select
                value={currentPage}
                onChange={(e) => setCurrentPage(e.target.value)}
                id="page-select"
            >
                {pages.map((page) => {
                    return (
                        <MenuItem key={page} value={page}>
                            {page}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
        </div>
    );
}
export default PageList;
