import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import { useState, useEffect } from "react";

function ErrorGridCell({ errorKey, event, neighbors, modalGrid }) {
  const [numNeighbors, setNumNeighbors] = useState(0);

  const countValidNeighbors = () => {
    try {
      if (neighbors) {
        for (const obj_id in neighbors) {
          if (
            neighbors[obj_id][event] !== null &&
            neighbors[obj_id][event] !== undefined &&
            neighbors[obj_id][event].hasOwnProperty("url")
          ) {
            const img = new Image();
            img.onload = () => {
              setNumNeighbors((prev) => prev + 1);
            };
            img.src = neighbors[obj_id][event]["url"];
          }
        }
      }
      return 0;
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    countValidNeighbors();
  }, []);

  return (
    <TableCell
      key={errorKey}
      align="center"
      sx={{
        backgroundColor: "#fafafa",
        padding: "4px 4px",
        height: 80,
      }}
      component={Paper}
    >
      <Paper
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "98%",
          height: "100%",
          backgroundColor: "#e63946",
        }}
      >
        {" "}
        {modalGrid !== true && (
          <div style={{ fontSize: "14px", color: "white" }}>{numNeighbors}</div>
        )}
      </Paper>
    </TableCell>
  );
}

export default ErrorGridCell;
