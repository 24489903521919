import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import ImageGridRow from "../ImageGridRow";
import Table from "@mui/material/Table";
import { useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
function TargetEditRow({
  setTargetRow,

  targetRow,
  loading,
  checkedRows,
  setCheckedRows,
  setObjectList,
  swapTracks,
  rowIndex,
  headers,
  editObjects,
  editMode,
  resetTargetCell,
  initialTargetRowState,
  convertUnixToDate,
  outerList,
}) {
  console.log(initialTargetRowState);
  return (
    <div style={{ padding: "5px 15px" }}>
      <Paper elevation={3}>
        <Table
          elevation={10}
          sx={{
            tableLayout: "fixed",
          }}
        >
          <TableBody sx={{ border: "1px solid #fafafa" }}>
            <ImageGridRow
              setTargetRow={setTargetRow}
              outerList={outerList}
              convertUnixToDate={convertUnixToDate}
              isTargetRow={true}
              editMode={editMode}
              row={targetRow}
              loading={loading}
              checkedRows={checkedRows}
              setCheckedRows={setCheckedRows}
              objectList={editObjects}
              setObjectList={setObjectList}
              swapTracks={swapTracks}
              rowIndex={rowIndex}
              headers={headers}
              showButton={false}
              resetTargetCell={resetTargetCell}
              initialTargetRowState={initialTargetRowState}
            ></ImageGridRow>
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}
export default TargetEditRow;
