import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import ErrorGridCell from "./Grid Cells/ErrorGridCell";
import GrayGridCell from "./Grid Cells/GrayGridCell";
import StandardGridCell from "./Grid Cells/StandardGridCell";
import ActionCell from "./Grid Cells/ActionCell";
import TargetGridCell from "./Grid Cells/TargetGridCell";

import _ from "lodash";
function ImageGridRow({
  theme,
  editMode,
  row,
  loading,
  checkedRows,
  setCheckedRows,
  objectList,
  setObjectList,
  swapTracks,
  rowIndex,
  headers,
  showButton,
  isTargetRow,
  resetTargetCell,
  initialTargetRowState,
  activeLocation,
  date,
  convertUnixToDate,
  authRequest,
  user,
  modalGrid,
  setTargetRow,
  outerList = null,
}) {
  let neighbors = null;
  return (
    row[1] &&
    !_.isEmpty(row[1]) && (
      <TableRow sx={{ height: 50 }} key={row}>
        <TableCell
          key={row[0]}
          align="center"
          sx={{ padding: "4px 6px", margin: 0, height: 80 }}
        >
          {row[0]}
        </TableCell>

        {Object.entries(row[1])
          .filter((obj) => {
            if (obj[0] === "neighbors") {
              neighbors = row[1][obj[0]];
            }
            return obj[0] !== "key" && obj[0] !== "neighbors";
          })
          .map((obj, colIndex) => {
            let eventName = obj[0];
            let eventDetails = obj[1];
            let convertedTimestamp;

            try {
              if (colIndex === 0) {
                return (
                  <TableCell
                    key={eventDetails.key}
                    align="center"
                    sx={{ padding: "4px 6px", margin: 0, height: 80 }}
                  >
                    {eventDetails.timestamp}
                  </TableCell>
                );
                //action cell (connect/delete tracks buttons)
              }
            } catch (error) {
              console.log(error);
            }
            if (
              !eventDetails.hasOwnProperty("timestamp") ||
              eventDetails.timestamp === null ||
              eventDetails["timestamp"] === undefined
            ) {
              return (
                <ErrorGridCell
                  modalGrid={modalGrid}
                  key={eventDetails.key}
                  errorKey={eventDetails.key}
                  event={eventName}
                  neighbors={neighbors}
                ></ErrorGridCell>
              );
            } else if (
              eventDetails.timestamp &&
              eventDetails["url"] === undefined
            ) {
              return (
                <GrayGridCell
                  key={eventDetails.key}
                  grayKey={eventDetails.key}
                  timestamp={eventDetails.timestamp}
                ></GrayGridCell>
              );
            }

            return editMode !== true ? (
              <StandardGridCell
                modalGrid={modalGrid}
                key={eventDetails.key}
                initialTargetRowState={initialTargetRowState}
                resetTargetCell={resetTargetCell}
                isChecked={eventDetails.isChecked}
                event={eventName}
                timestamp={eventDetails.timestamp}
                row={row}
                neighbors={neighbors}
                setTargetRow={setTargetRow}
                key={eventDetails.key}
                initialTargetRowState={initialTargetRowState}
                resetTargetCell={resetTargetCell}
                isTargetRow={isTargetRow}
                swapTracks={swapTracks}
                timestamp={eventDetails.timestamp}
                editMode={editMode}
                loading={loading}
                checkedRows={checkedRows}
                standardKey={eventDetails.key}
                url={eventDetails.url}
                objectList={objectList}
                setObjectList={setObjectList}
                rowIndex={rowIndex}
                colIndex={colIndex}
                outerList={outerList}
                setCheckedRows={setCheckedRows}
                eventObj={eventDetails}
                isChecked={eventDetails.isChecked}
                event={eventName}
              ></StandardGridCell>
            ) : (
              <TargetGridCell
                row={row}
                setTargetRow={setTargetRow}
                key={eventDetails.key}
                initialTargetRowState={initialTargetRowState}
                resetTargetCell={resetTargetCell}
                isTargetRow={isTargetRow}
                swapTracks={swapTracks}
                timestamp={eventDetails.timestamp}
                editMode={editMode}
                loading={loading}
                checkedRows={checkedRows}
                standardKey={eventDetails.key}
                url={eventDetails.url}
                objectList={objectList}
                setObjectList={setObjectList}
                rowIndex={rowIndex}
                colIndex={colIndex}
                outerList={outerList}
                setCheckedRows={setCheckedRows}
                eventObj={eventDetails}
                isChecked={eventDetails.isChecked}
                event={eventName}
              ></TargetGridCell>
            );
          })}
        {editMode === false && showButton !== false && modalGrid !== true && (
          <ActionCell
            user={user}
            authRequest={authRequest}
            date={date}
            activeLocation={activeLocation}
            convertUnixToDate={convertUnixToDate}
            actionKey={objectList[rowIndex][1]}
            editMode={editMode}
            loading={loading}
            checkedRows={checkedRows}
            swapTracks={swapTracks}
            rowIndex={rowIndex}
            headers={headers}
            objectList={objectList}
            setObjectList={setObjectList}
            row={row}
            setCheckedRows={setCheckedRows}
          ></ActionCell>
        )}
      </TableRow>
    )
  );
}
export default ImageGridRow;
