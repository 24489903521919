import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ImageGridRow from "./ImageGridRow";
import Paper from "@mui/material/Paper";
import HeaderCell from "./Grid Cells/HeaderCell";
import DataNotFound from "./DataNotFound";
import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
function ImageGrid({
  user,
  theme,
  editMode,
  dataNotFound,
  headers,
  objectList,
  setObjectList,
  loading,
  targetRow,
  setTargetRow,
  resetTargetCell,
  date,
  activeLocation,
  convertUnixToDate,
  tableHeight,
  authRequest,
  errorFetching,
  modalGrid,
}) {
  {
    console.log(headers, errorFetching);
  }
  const [checkedRows, setCheckedRows] = useState(new Set());
  const [swapTrackLoading, setSwapTrackLoading] = useState(false);

  if (dataNotFound === true && errorFetching === false) {
    return (
      <DataNotFound
        key={headers}
        message={"No data found. Try another Date."}
      ></DataNotFound>
    );
  }
  const swapTracks = (clickedRowIndex, clickedEvent) => {
    if (
      targetRow &&
      targetRow[1].hasOwnProperty(clickedEvent) &&
      clickedEvent !== "DRIVE_THRU_ORDER_ENTER"
    ) {
      setTargetRow([
        targetRow[0],
        {
          ...targetRow[1],
          [clickedEvent]: {
            ...objectList[clickedRowIndex][1][clickedEvent],
            disabled: false,
          },
        },
      ]);
    }

    /*
    var swapIndex;
    for (const index of checkedRows) {
      console.log(index);
      if (index !== clickedRowIndex) {
        swapIndex = index;
      }
    }
    console.log(swapIndex);
    console.log(objectList[clickedRowIndex][1]);
    console.log(objectList[swapIndex][1]);
    */
  };

  const selectAllTracks = (index) => {
    const resultObjectList = [...objectList];
    for (let obj in resultObjectList[index][1]) {
      if (resultObjectList[index][1][obj].hasOwnProperty("isChecked")) {
        resultObjectList[index][1][obj].isChecked = true;
      }
    }
    if (!checkedRows.has(index)) {
      const resultChecked = new Set(checkedRows);
      resultChecked.add(index);
      setCheckedRows(resultChecked);
    }
    setObjectList(resultObjectList);
  };
  const clearTracks = (index = null) => {
    const resultObjectList = [...objectList];
    //clear specific track
    if (index !== null) {
      for (let obj in resultObjectList[index][1]) {
        if (resultObjectList[index][1][obj].hasOwnProperty("isChecked")) {
          resultObjectList[index][1][obj].isChecked = false;
        }
      }
      if (checkedRows.has(index)) {
        const resultChecked = new Set(checkedRows);
        resultChecked.delete(index);
        setCheckedRows(resultChecked);
      }
      setObjectList(resultObjectList);
      return;
    }
    //clear tracks of all rows with checked cells

    for (const rowIndex of checkedRows) {
      for (let obj in resultObjectList[rowIndex][1]) {
        if (resultObjectList[index][1][obj].hasOwnProperty("isChecked")) {
          resultObjectList[rowIndex][1].isChecked = false;
        }
      }
    }
    setObjectList(resultObjectList);
  };

  return (
    <div className="content-container" id="image-grid-content">
      {swapTrackLoading && (
        <CircularProgress
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        ></CircularProgress>
      )}
      {!dataNotFound && objectList && objectList.length > 0 && (
        <TableContainer
          elevation={1}
          className="object-table-container"
          sx={{
            width: "98%",
            margin: "auto",
            minWidth: "50vw",
            marginTop: "7px",
            borderRadius: "3px",
            maxHeight: `${tableHeight}`,
          }}
          component={Paper}
        >
          <Table id="object-table" sx={{ tableLayout: "fixed" }}>
            <TableHead
              sx={{
                position: "sticky",
                top: "0px",
                zIndex: "100",
                transition: "ease",
              }}
            >
              <TableRow
                sx={{ backgroundColor: "#1d3557", whiteSpace: "nowrap" }}
              >
                {headers &&
                  errorFetching === false &&
                  headers.map((item, index) => {
                    //create row of header cells

                    return (
                      <HeaderCell
                        theme={theme}
                        key={item}
                        color={"white"}
                        item={item}
                      ></HeaderCell>
                    );
                  })}
                {editMode === false &&
                  errorFetching === false &&
                  headers.length > 0 &&
                  modalGrid !== true && (
                    <HeaderCell color={"#fffc45"} item={""}></HeaderCell>
                  )}
              </TableRow>
            </TableHead>
            <TableBody sx={{ padding: "0px", overflowY: "scroll" }}>
              {objectList.map((row, rowIndex) => {
                if (row && Object.keys(row).length !== 0) {
                  console.log(row);

                  return (
                    //create table body from list of objects
                    <ImageGridRow
                      modalGrid={modalGrid}
                      authRequest={authRequest}
                      theme={theme}
                      key={row}
                      convertUnixToDate={convertUnixToDate}
                      resetTargetCell={resetTargetCell}
                      editMode={editMode}
                      row={row}
                      date={date}
                      activeLocation={activeLocation}
                      loading={loading}
                      checkedRows={checkedRows}
                      setCheckedRows={setCheckedRows}
                      objectList={objectList}
                      setObjectList={setObjectList}
                      swapTracks={swapTracks}
                      rowIndex={rowIndex}
                      headers={headers}
                      user={user}
                    ></ImageGridRow>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
export default ImageGrid;
