import { useState, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GrayGridCell from "./GrayGridCell";
import Popover from "@mui/material/Popover";
import Switch from "@mui/material/Switch";
import { Alert, Snackbar } from "@mui/material";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import Button from "@mui/material/Button";
function StandardGridCell({
  url,
  timestamp,
  objectList,
  rowIndex,
  setObjectList,
  checkedRows,
  setCheckedRows,
  event,
  editMode,
  swapTracks,
  isTargetRow,
  resetTargetCell,
  standardKey,

  modalGrid,
  disabled,
  neighbors,
}) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [imgError, setImgError] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [checked, setChecked] = useState(false);
  let opacity;
  const [numNeighbors, setNumNeighbors] = useState(0);
  disabled ? (opacity = "25%") : (opacity = "100%");
  let handleImgError = () => {
    setImgError(true);
  };
  useEffect(() => {
    countValidNeighbors();
  }, []);
  const countValidNeighbors = () => {
    try {
      if (neighbors) {
        for (const obj_id in neighbors) {
          if (neighbors[obj_id][event].hasOwnProperty("url")) {
            const img = new Image();
            img.onload = () => {
              console.log("hi");
              setNumNeighbors((prev) => prev + 1);
            };
            img.src = neighbors[obj_id][event]["url"];
          }
        }
      }
      return 0;
    } catch (error) {
      console.log(error);
    }
  };
  const handleSwitchChange = () => {
    const objectListResult = [...objectList];
    let eventObj = objectListResult[1][event];
    eventObj.isChecked = !eventObj.isChecked;
    if (eventObj.isChecked === true) {
      if (checkedRows.size === 2 && !checkedRows.has(rowIndex)) {
        eventObj.isChecked = false;
        setSnackbarOpen(true);
      } else {
        const resultCheckedRows = new Set(checkedRows);
        resultCheckedRows.add(rowIndex);
        setCheckedRows(resultCheckedRows);
      }
    } else {
      setObjectList(objectListResult);
    }
  };
  /*
        if (rowIndex in checkedCells.keys()) {
          setCheckedRows((prev) => ({
            ...prev,
            rowIndex: [...checkedRows[rowIndex], colIndex],
          }));
        } else {
          setCheckedRows((prev) => ({
            ...prev,
            rowIndex: [colIndex],
          }));
        }
      }
    } else {
      let index = checkedRows[rowIndex].indexOf();
    }
      {editMode === true && (
          <Switch
            disabled={loading}
            onChange={handleSwitchChange}
            checked={objectList[rowIndex][1][event].isChecked}
            size="small"
            sx={{ margin: "0px", marginBottom: "2px", marginLeft: "auto" }}
          ></Switch>
    */

  const handlePopoverOpen = (e) => {
    setAnchorElement(e.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorElement(null);
  };
  if (imgError) {
    return (
      <GrayGridCell grayKey={standardKey} timestamp={timestamp}></GrayGridCell>
    );
  }
  const open = Boolean(anchorElement);
  console.log(modalGrid, editMode);
  return (
    <TableCell
      key={standardKey}
      onClick={
        modalGrid !== true
          ? (e) => handlePopoverOpen(e)
          : () => swapTracks(rowIndex, event)
      }
      align="center"
      component={Paper}
      sx={{
        backgroundColor: "#fafafa",
        padding: "4px 4px",
        overflowY: "auto",
        opacity: opacity,
      }}
    >
      <Snackbar
        message={"Only two rows can be selected at once"}
        autoHideDuration={3000}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Only two rows can be selected at once
        </Alert>
      </Snackbar>
      <Paper className="image-and-time-container">
        {modalGrid !== true && (
          <div
            style={{
              color: "white",
              width: "100%",
              backgroundColor: "#1d3557",
            }}
          >
            {numNeighbors}
          </div>
        )}

        {isTargetRow === true && (
          <Button
            variant="contained"
            color="error"
            onClick={() => resetTargetCell(event)}
            sx={{
              width: "20%",
              padding: "0px",
              marginBottom: "2px",
              margin: "auto",
              fontSize: "11px",
            }}
          >
            Reset
          </Button>
        )}
        <img
          className={
            editMode === false && modalGrid !== true
              ? "grid-image"
              : modalGrid === true
              ? "modal-grid-image"
              : isTargetRow === true
              ? "target-grid-image"
              : "edit-grid-image"
          }
          alt="car object"
          value={event}
          src={url}
          onError={handleImgError}
        />
        <div className="grid-cell-timestamp">
          <AccessTimeIcon
            style={{ position: "relative", right: "4px", fontSize: "18px" }}
          ></AccessTimeIcon>
          {timestamp}
        </div>
      </Paper>
    </TableCell>
  );
}

export default StandardGridCell;
