import DatePick from "./DatePick";
import Button from "@mui/material/Button";
import LocationList from "./LocationList";
import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { v4 as uuid4 } from "uuid";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { Snackbar } from "@mui/material";
function Header({
  convertDate,
  headers,
  setHeaders,
  setDataNotFound,
  objectList,
  setObjectList,
  loading,
  setLoading,
  date,
  setDate,
  activeLocation,
  setActiveLocation,
  currentPage,
  setTotalPages,
  authRequest,
  errorFetching,
  setErrorFetching,
}) {
  console.log(errorFetching, objectList, headers);
  const [locations, setLocations] = useState([]);
  const [neighbors, setNeighbors] = useState([]);
  //convert javascript date to yyyy-mm-dd string
  /*<<<<<<< Updated upstream
=======
    />


  const convertDate = (e) => {
    console.log(e);
    console.log(
      e.toLocaleString("en-US", {
        timeZone: "America/Los_Angeles",
      })
    );
    console.log(typeof e, typeof new Date());
    console.log(
      new Date(
        e.toLocaleString("en-US", {
          timeZone: "America/Los_Angeles",
        })
      )
    );
    //console.log(convertedDate.toISOString().split("T")[0]);
    //setDate(convertedDate.toISOString().split("T")[0]);
  };
  */
  //>>>>>>> Stashed changes

  let scrollTo = (id) => {
    document.getElementById(id).scrollIntoView();
  };

  const getObjects = async () => {
    setLoading(true);
    console.log(await authRequest());
    fetch(
      `https://p01g94svgb.execute-api.us-west-2.amazonaws.com/prod/retrack-staging?call=get-no-match-with-neighbors&date=${date}&location_code=${activeLocation}&page=${currentPage}`,
      {
        headers: new Headers(await authRequest()),
      }
    )
      .then((response) =>
        response.json().then((res) => {
          res = JSON.parse(res);
          console.log(res);
          if (res[0].length > 0 && Object.keys(res[1]).length > 0) {
            setHeaders(["Object ID", ...res[0]]); //add headers for select button and object id
          } else {
            setHeaders([]);
          }
          //res[1] is the list of ids (each id has an object of events)
          console.log(res[1]);

          console.log(res[1]);
          try {
            console.log(res[1]);
            for (const obj in res[1]) {
              console.log(res[1][obj]);
              res[1][obj]["key"] = uuid4();
              for (const neighbors in res[1][obj]["neighbors"]) {
                for (const event in res[1][obj]["neighbors"][neighbors]) {
                  res[1][obj]["neighbors"][neighbors][event]["key"] = uuid4();
                }
              }
              for (const event in res[1][obj]) {
                if (typeof res[1][obj][event] === "object") {
                  res[1][obj][event]["key"] = uuid4();
                  res[1][obj][event]["disabled"] = false;
                }
              }
            }
          } catch (error) {
            console.log(error);
          }
          console.log(res[1].entries);
          setObjectList(Object.entries(res[1]));
          setTotalPages(Object(res[2]));
        })
      )
      .catch((error) => {
        setErrorFetching(true);

        console.log(error);
      });
  };
  useEffect(() => {
    if (errorFetching === true) {
      console.log("hi");
      setObjectList({});
      setHeaders([]);
      setLoading(false);
    }
  }, [errorFetching]);

  useEffect(() => {
    if (headers && headers.length > 0) {
      setDataNotFound(false);
    } else if (headers && headers.length === 0 && errorFetching === false) {
      setDataNotFound(true);
    }
  }, [headers]);

  useEffect(() => {
    if (objectList) {
      setLoading(false);
    }
  }, [objectList]);

  useEffect(() => {
    if (!loading && objectList.length > 0 && errorFetching === false) {
      scrollTo("page-count-content");
    }
  }, [loading]);
  useEffect(() => {
    async function fetchData() {
      // You can await here
      fetch(
        "https://g5gp2kln71.execute-api.us-west-2.amazonaws.com/first-deploy/-locations",
        {
          headers: new Headers(await authRequest()),
        }
      )
        .then((response) =>
          response.json().then((data) => {
            setLocations(data);
          })
        )
        .catch((error) => {
          console.log(error);
        });
    }
    fetchData();
  }, []);

  return (
    <div>
      <header className="header-container">
        <DatePick
          convertDate={convertDate}
          setDate={setDate}
          date={date}
        ></DatePick>
        <LocationList
          locations={locations}
          activeLocation={activeLocation}
          setActiveLocation={setActiveLocation}
        ></LocationList>
        <Button
            disabled={activeLocation === "" || loading || errorFetching === true}
            onClick={getObjects}
            id="show-grid-button"
        >
          Show Grid
        </Button>
        <Button
            variant="contained"
            onClick={() => {
              alert('To generate the tracks for a new page, press SHOW GRID after selecting the desired page.');
            }}
        >
          Help
        </Button>
        {loading ? (
          <CircularProgress id="loading-wheel"></CircularProgress>
        ) : null}
        {errorFetching && (
          <Snackbar
            autoHideDuration={3000}
            open={errorFetching}
            onClose={() => setErrorFetching(false)}
            sx={{
              position: "absolute",
              width: "100%",
              top: "150%",
              left: "100px",
            }}
          >
            <Alert severity="error" sx={{ width: "50%", margin: "auto" }}>
              <AlertTitle>Error</AlertTitle>
              An error occurred while fetching data
            </Alert>
          </Snackbar>
        )}
      </header>
    </div>
  );
}
export default Header;
