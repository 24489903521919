import TableCell from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import json2mq from "json2mq";

function HeaderCell({ item, color, theme }) {
  const matches = useMediaQuery(
    json2mq({
      minWidth: 600,
    })
  );
  return (
    <TableCell
      align="center"
      sx={{
        padding: ["10px 0px", "12px 0px"],
        fontSize: ["4px", "6px", "8px"],
        fontWeight: 700,
        overflowX: "auto",
        overflowY: "auto",
        color: `${color}`,
        "@keyframes move-text": {
          from: { transform: "translate(0,0)" },
          to: { transform: "translate(0,0)" },
        },
      }}
    >
      {item}
    </TableCell>
  );
}

export default HeaderCell;
