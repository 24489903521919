import Header from "./Header/Header";
import ImageGrid from "./Grid/ImageGrid";
import PageList from "./Header/PageList"
import { useState, useRef, useEffect } from "react";
import moment from "moment-timezone";
import { useAuth0 } from "@auth0/auth0-react";

function Home({ theme }) {
  const convertDate = (e, defaultDate = false) => {
    console.log(e);
    const offset = e.getTimezoneOffset();
    e = new Date(e.getTime() - offset * 60 * 1000);
    const resultStringDate = e.toISOString().split("T")[0];
    console.log(resultStringDate);
    setDate(resultStringDate);
  };
  const [objectList, setObjectList] = useState([]);
  const [neighbors, setNeighbors] = useState([]);
  const [headers, setHeaders] = useState(null);
  const [dataNotFound, setDataNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeLocation, setActiveLocation] = useState("");
  const [currentPage, setCurrentPage] = useState("1");
  const [totalPages, setTotalPages] = useState([1]);
  const [date, setDate] = useState(null);
  const [errorFetching, setErrorFetching] = useState(false);
  const mounted = useRef(false);
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
  useEffect(() => {
    if (mounted.current === false) {
      setDate(convertDate(new Date()));
      mounted.current = true;
    }
  }, []);

  async function authRequest() {
    return {
      Authorization: `Bearer ${await getAccessTokenSilently({
        username: user.email,
      })}`,
    };
  }

  function convertUnixToDate(unixTimestamp) {
    let timezone = "America/Los_Angeles";
    let timestamp = moment.unix(unixTimestamp);
    return moment.tz(timestamp, timezone).format("HH:mm:ss A z");
  }
  return (
    <>
      <Header
        convertDate={convertDate}
        setErrorFetching={setErrorFetching}
        errorFetching={errorFetching}
        authRequest={authRequest}
        setDataNotFound={setDataNotFound}
        setHeaders={setHeaders}
        headers={headers}
        objectList={objectList}
        setObjectList={setObjectList}
        loading={loading}
        setLoading={setLoading}
        setDate={setDate}
        setActiveLocation={setActiveLocation}
        activeLocation={activeLocation}
        currentPage = {currentPage}
        setCurrentPage = {setCurrentPage}
        totalPages={totalPages}
        setTotalPages={setTotalPages}
        date={date}
      ></Header>
      <PageList
          pages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
      ></PageList>
      <ImageGrid
        errorFetching={errorFetching}
        user={user}
        authRequest={authRequest}
        theme={theme}
        convertUnixToDate={convertUnixToDate}
        date={date}
        activeLocation={activeLocation}
        dataNotFound={dataNotFound}
        headers={headers}
        objectList={objectList}
        setObjectList={setObjectList}
        loading={loading}
        editMode={false}
        tableHeight={"100vh"}
      ></ImageGrid>
    </>
  );
}

export default Home;
