import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import ImageGrid from "../ImageGrid";
import TargetEditRow from "./TargetEditRow";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import moment2 from "moment-timezone";
import moment from "moment";
import _ from "lodash";
function TrackModal({
  user,
  trackModalOpen,
  setTrackModalOpen,
  saveToList,
  setSaveToList,
  objectList,
  setObjectList,
  headers,
  loading,
  checkedRows,
  setCheckedRows,
  swapTracks,
  rowIndex,
  editObjects,
  date,
  activeLocation,
  convertUnixToDate,
  authRequest,
}) {
  const [initialTargetRowState, setInitialTargetRowState] = useState([
    ...editObjects,
  ]);
  const [targetRow, setTargetRow] = useState([...editObjects]);
  const [saved, setSaved] = useState(false);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const timezoneMap = [
    { abbrv: "EST", offset: "-5:00" },
    { abbrv: "EDT", offset: "-4:00" },
    { abbrv: "CST", offset: "-6:00" },
    { abbrv: "CDT", offset: "-5:00" },
    { abbrv: "MST", offset: "-7:00" },
    { abbrv: "MDT", offset: "-6:00" },
    { abbrv: "PST", offset: "-8:00" },
    { abbrv: "PDT", offset: "-7:00" },
  ];
  const checkIfEmpty = () => {
    if (
      objectList === null ||
      objectList === undefined ||
      objectList.length === 0 ||
      objectList.length === undefined
    ) {
      return true;
    }

    const filteredList = objectList.filter((item) => item[0] != "key");
    for (const obj in filteredList) {
      if (!_.isEmpty(filteredList[obj][1])) {
        return false;
      }
    }
    return true;
  };
  let empty = checkIfEmpty();

  const handleClose = () => {
    if (saved !== true) {
      setTargetRow(initialTargetRowState);
    }
    setTrackModalOpen(false);
  };

  useEffect(() => {
    if (saved === true) {
      const updateTrack = async () => {
        try {
          const objId = targetRow[0];
          var changes = [];
          var index = 0;
          for (const obj in targetRow[1]) {
            if (obj !== "key" && obj !== "neighbors") {
              if (
                targetRow[1][obj].hasOwnProperty("url") &&
                targetRow[1][obj]["url"] !==
                  initialTargetRowState[1][obj]["url"]
              ) {
                const offset = timezoneMap.find(
                  (tz) =>
                    tz.abbrv === targetRow[1][obj]["timestamp"].split(" ")[1]
                ).offset;
                let currDate = new Date(
                  `${date}T${targetRow[1][obj]["timestamp"].split(" ")[0]}`
                );

                console.log(currDate);
                console.log(currDate.toLocaleString());
                const t = `${currDate.getFullYear()}-${(
                  "0" +
                  (currDate.getMonth() + 1)
                ).slice(-2)}-${("0" + currDate.getDate()).slice(-2)} ${
                  targetRow[1][obj]["timestamp"].split(" ")[0]
                }${offset}`;
                console.log(moment(t));

                changes.push({
                  event_type: Object.keys(targetRow[1])[index], //neighbors is first index
                  event_value: targetRow[1][obj]["event_value"],
                  ts: moment(t).unix(),
                });
                console.log(changes);
              }
              if (targetRow[1][obj]["disabled"] === true) {
                changes.push({
                  event_type: Object.keys(targetRow[1])[index],
                  event_value: targetRow[1][obj]["event_value"],
                  ts: null,
                });
              }
              index += 1;
            }
          }
          console.log(authRequest());
          console.log(
            new Headers({
              ...(await authRequest()),
              "Content-Type": "application/json",
            })
          );
          fetch(
            `https://lk6e3mc867.execute-api.us-west-2.amazonaws.com/beta/retrack?date=${date}&location_code=${activeLocation}`,
            {
              method: "POST",
              headers: new Headers({
                ...(await authRequest()),
                "Content-Type": "application/json",
              }),
              body: JSON.stringify({
                call: "update-track",
                username: user.email.split("@")[0],
                changes: JSON.stringify(changes),
                object_id: objId,
              }),
            }
          );
        } catch (error) {
          console.log(error);
        }
      };
      updateTrack();
      storeEditedRow(saveToList, setSaveToList);
      handleClose();
      setSaved(false);
    }
  }, [saved]);
  const test = checkIfEmpty();
  const resetTargetCell = (event) => {
    try {
      const initialTargetCell = initialTargetRowState[1][event];
      setTargetRow([
        targetRow[0],
        {
          ...targetRow[1],
          [event]: { ...initialTargetCell, disabled: "false" },
        },
        targetRow[2],
      ]);
    } catch (error) {
      console.log(error);
    }
  };
  const storeEditedRow = (saveList, setSaveList) => {
    try {
      const resultSaveList = [...saveList];
      resultSaveList[rowIndex][1] = targetRow[1];

      setSaveList(resultSaveList);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal open={trackModalOpen} onClose={handleClose}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          border: "none",
          left: "0px",
          right: "0px",
          top: "0px",
          bottom: "0px",
          margin: "auto auto",
          backgroundColor: "white",
          position: "absolute",
          width: "90%",
          height: "98%",
        }}
      >
        <div className="edit-modal-container">
          <TargetEditRow
            setTargetRow={setTargetRow}
            convertUnixToDate={convertUnixToDate}
            outerList={objectList}
            setObjectList={setObjectList}
            editMode={true}
            targetRow={targetRow}
            loading={loading}
            checkedRows={checkedRows}
            setCheckedRows={setCheckedRows}
            objectList={targetRow[1]}
            outerList={objectList}
            setObjectList={setObjectList}
            swapTracks={swapTracks}
            rowIndex={rowIndex}
            headers={headers}
            editObjects={targetRow[1]}
            resetTargetCell={resetTargetCell}
            initialTargetRowState={initialTargetRowState}
          ></TargetEditRow>
          <ImageGrid
            modalGrid={true}
            resetTargetCell={resetTargetCell}
            editMode={false}
            dataNotFound={empty}
            headers={headers}
            objectList={objectList.filter((item) => item[0] !== "key")}
            setObjectList={setObjectList}
            targetRow={targetRow}
            setTargetRow={setTargetRow}
            targetRowIndex={rowIndex}
            dataNotFound={empty}
            convertUnixToDate={convertUnixToDate}
            tableHeight={"100%"}
            errorFetching={false}
          ></ImageGrid>
        </div>
        <div className="modal-save-buttons">
          <Button
            color="success"
            variant="contained"
            disabled={_.isEqual(initialTargetRowState, targetRow)}
            onClick={() => setSaved(true)}
          >
            Save Changes
          </Button>
          <Button color="error" variant="contained" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}

export default TrackModal;
