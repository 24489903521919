import { useEffect, useState } from "react";
import Calendar from "react-calendar";

function DatePick({ date, convertDate, setDate }) {
  return (
    <div id="header-calendar" style={{ display: "flex" }}>
      <Calendar onClickDay={(e) => convertDate(e)}></Calendar>
    </div>
  );
}

export default DatePick;
